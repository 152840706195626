import React, { useState } from "react"
import { navigate } from "@reach/router"
import { useDispatch, useSelector } from "react-redux"
import LoginImage from "src/assets/images/new/zdjecie_do_logowania.jpg"

import { useForm } from "src/hooks"
import withAuth from "src/hoc/withAuth"

import { Button } from "src/styled"
import { Input, Loading } from "src/components/common"
import {
    Form,
    Wrapper,
    FormContainer,
    ImageWrapper,
    StyledImage,
    TopLeftShadow,
    StyledContainer,
    ButtonContainer,
    Title,
    ErrorMessage,
    ForgotPassword,
} from "src/modules/auth/components"
import { signIn } from "src/redux/user"
import { validateSignIn } from "src/modules/auth/validate"
import ResetPasswordModal from "src/modules/auth/ResetPasswordModal"
import { reduceErrors } from "src/modules/auth/helper"
import { BackgroundImage } from "src/modules/Main/Presentation"

const initState = {
    email: "",
    password: "",
}

function SignIn() {
    const { state, handleChange } = useForm(initState)
    const [errors, setErrors] = useState({})
    const [resettingPassword, setResettingPassword] = useState(false)
    const { signInError, isAuthenticating } = useSelector(state => state.user)

    const dispatch = useDispatch()

    function handleSubmit(event) {
        event.preventDefault()
        if (!onValidate(state)) {
            return
        }

        dispatch(signIn(state.email, state.password))
    }

    function onValidate(state) {
        const { error } = validateSignIn(state)

        if (error) {
            const { details } = error
            const errorMessages = details.reduce(reduceErrors, {})
            setErrors(errorMessages)

            return false
        } else {
            setErrors({})
            return true
        }
    }

    return (
        <React.Fragment>
            <StyledContainer>
                <Wrapper>
                    <TopLeftShadow />

                    <FormContainer>
                        <Title> Logowanie </Title>

                        <Form isActive>
                            <Input
                                error={errors.email}
                                value={state.email}
                                label={"Mail"}
                                name="email"
                                onChange={handleChange}
                            />

                            <Input
                                error={errors.password}
                                value={state.password}
                                label={"Hasło"}
                                name="password"
                                type="password"
                                onChange={handleChange}
                            />

                            {signInError && (
                                <ErrorMessage>{signInError}</ErrorMessage>
                            )}

                            <ButtonContainer>
                                <Button
                                    onClick={handleSubmit}
                                    variant={"primary-wide"}
                                    disabled={isAuthenticating}
                                >
                                    {isAuthenticating ? (
                                        <Loading color="white" />
                                    ) : (
                                        "Zaloguj się"
                                    )}
                                </Button>

                                <Button
                                    variant={"primary-reverse-wide"}
                                    onClick={() => navigate("/auth/sign-up")}
                                >
                                    Rejestracja
                                </Button>
                            </ButtonContainer>

                            <ForgotPassword
                                onClick={() => setResettingPassword(true)}
                            >
                                Zapomniałeś hasła?
                            </ForgotPassword>
                        </Form>
                    </FormContainer>

                    <ImageWrapper>
                        <StyledImage
                            src={
                                // "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcbsnews1.cbsistatic.com%2Fhub%2Fi%2F2017%2F05%2F12%2Ff9e143b5-9392-478e-8b5a-941e6986c4df%2Fistock-511991334.jpg&f=1&nofb=1"
                                LoginImage
                            }
                        />
                        {/* <BackgroundImage src={LoginImage} /> */}
                    </ImageWrapper>
                </Wrapper>
            </StyledContainer>

            {resettingPassword && (
                <ResetPasswordModal
                    onClose={() => setResettingPassword(false)}
                />
            )}
        </React.Fragment>
    )
}

export default withAuth(SignIn, false)
