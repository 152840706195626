//@ts-check
import React, { useState } from "react"
import { Input, Modal } from "src/components/common"
import LoadingIcon from "src/components/common/Loading"
import { Button, Flex, Text } from "src/styled"
import styled from "styled-components"
import { resetPassword, sendVerificationResetPassword } from "src/api/rest/auth"

const Wrapper = styled(Flex)`
    flex-direction: column;
    flex: 1;
`

const ResetState = {
    SEND_VERIFICATION: 0,
    RESETING: 1,
    SUCCESS: 2,
}

export default function ResetPasswordModal({ onClose }) {
    const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [newPassword, setNewPassword] = useState("")
    const [verifictionCode, setVerificationCode] = useState("")
    const [error, setError] = useState("")
    const [formState, setFormState] = useState(ResetState.SEND_VERIFICATION)

    async function onSendVerifycationPassword() {
        setIsLoading(true)
        try {
            await sendVerificationResetPassword(email)
        } catch (error) {
            setError(error)
            console.error(error)
        }
        setFormState(ResetState.RESETING)
        setIsLoading(false)
    }

    async function onResetPassword() {
        try {
            await resetPassword(email, newPassword, verifictionCode)
            setFormState(ResetState.SUCCESS)
            setError("")
        } catch (error) {
            setError(error)
            console.error(error)
        }
    }

    return (
        <Modal title="Przypomnienie hasła" onClose={onClose}>
            <Wrapper>
                {formState === ResetState.SEND_VERIFICATION && (
                    <React.Fragment>
                        <Input
                            label="Email"
                            value={email}
                            onChange={e => setEmail(e.currentTarget.value)}
                        />

                        <Button
                            onClick={onSendVerifycationPassword}
                            variant="primary"
                        >
                            {isLoading ? (
                                <LoadingIcon color="white" />
                            ) : (
                                "Przypomnij"
                            )}
                        </Button>
                    </React.Fragment>
                )}

                {formState === ResetState.RESETING && (
                    <React.Fragment>
                        <Text>
                            Sprawdź swojego maila, skopiuj otrzymany kod
                            weryfikacyjny oraz podaj nowe hasło.
                        </Text>

                        <Input
                            value={verifictionCode}
                            onChange={e =>
                                setVerificationCode(e.currentTarget.value)
                            }
                            label="Kod weryfikacyjny"
                        />

                        <Input
                            value={newPassword}
                            onChange={e =>
                                setNewPassword(e.currentTarget.value)
                            }
                            label="Nowe hasło"
                            type="password"
                        />

                        <Button onClick={onResetPassword} variant="primary">
                            {isLoading ? (
                                <LoadingIcon color="white" />
                            ) : (
                                "Ustaw hasło"
                            )}
                        </Button>
                    </React.Fragment>
                )}

                {formState === ResetState.SUCCESS && (
                    <React.Fragment>
                        <Text color="green">
                            Twoje hasło zostało poprawnie zmienione. Zaloguj się
                            ponownie!
                        </Text>
                    </React.Fragment>
                )}

                {error && <Text>{error}</Text>}
            </Wrapper>
        </Modal>
    )
}
